.PopDialogContainer {
    width: 474px;
    background: #ffffff;
    box-shadow: 0px 4px 8px 0px rgba(69, 88, 115, 0.12);
    border-radius: 8px 8px 8px 8px;
    padding: 24px;
}

.title {
    width: 160px;
    color: #0f294d;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    margin-bottom: 24px;
}

.subData {
    width: 426px;
    color: #0f294d;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
}
.subDataDesc {
    font-weight: 600;
}
.subDataDesContent {
    font-weight: 400;
}

.desc {
    width: 426px;
    color: #0f294d;
    font-size: 14px;
    font-weight: normal;
    line-height: 18px;
    margin-bottom: 24px;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    height: 38px;
}

.commonButton {
    width: 96px;
    height: 38px;
    border: 1px solid #3264ff;
    border-radius: 4px 4px 4px 4px;
    color: #3264ff;
    font-size: 16px;
    font-weight: 500;
    line-height: 38px;
    text-align: center;
    margin-right: 16px;
}

.primaryButton {
    width: 96px;
    height: 38px;
    background: #3264ff;
    border-radius: 4px 4px 4px 4px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    line-height: 38px;
    text-align: center;
}