@import '../../../../styles/list/todo/bem.scss';

$namespace: 'list';

@include b(lottery-dialog) {
	&.rc-dialog {
		width: 340px;
		margin: 0;
		max-height: 80%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-family: 'PingFangSC-Medium';
		background-color: transparent;
		font-size: 22px;
		color: rgb(114, 32, 10);
	}

	.rc-dialog-content {
		background-color: transparent;
	}
	.rc-dialog-header {
		border: none;
		padding: 0;
		height: 48px;
	}
	.rc-dialog-title {
		margin: 0 50px;
		font-size: 18px;
		line-height: 1.3;
		color: #333;
		font-weight: 600;
		text-align: center;
		top: 24px;
		position: relative;
	}
	.rc-dialog-body {
		padding: 0;
	}

	%redpack {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	@include e(redpack) {
		width: 340px;
		height: 360px;
		@extend %redpack;

		@include m(front) {
			width: 340px;
			height: 284px;
			top: 38px;
			// position: absolute;
			z-index: 99;
		}

		@include e(close-btn) {
			position: absolute;
			width: 28px;
			height: 28px;
			bottom: -26px;
			left: 156px;
		}
	}

	@include e(redpack-item) {
		@include e(btn) {
			width: 194px;
			height: 53px;
			top: 207px;
			left: 74px;
			cursor: pointer;
			@extend %redpack;
		}

		@include e(content) {
			width: 232px;
			height: 138px;
			top: 52px;
			left: 54px;
			position: absolute;
			display: flex;
			// justify-content: center;
			align-items: center;
			flex-direction: column;
			color: rgb(239, 17, 68);
			@include m(active) {
				// top: -35px;
				// transition: all 1s ease-in-out;
				animation: window-open 1s 1;
				animation-delay: 1s;
				animation-fill-mode: forwards;
			}

			@keyframes window-open {
				0% {
					top: 52px;
				}
				100% {
					top: -35px;
				}
			}

			@include e(coupon) {
				opacity: 0;
				margin-top: 19px;
				color: rgb(239, 17, 68);
				font-family: PingFangSC-Medium;
				font-size: 22px;
				font-weight: 500;
				animation: show-content 1s 1;
				animation-delay: 1s;
				animation-fill-mode: forwards;
			}

			@keyframes show-content {
				0% {
					opacity: 0;
				}
				100% {
					opacity: 1;
				}
			}

			@include e(tips) {
				margin-top: 8px;
				border-radius: 10px 10px 10px 10px;
				border: 0.5px solid rgb(239, 17, 68);
				height: 20px;
				width: 102px;
				color: rgb(239, 17, 68);
				font-family: PingFangSC-Regular;
				font-size: 12px;
				font-weight: normal;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
}
