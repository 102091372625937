.container {
    padding: 16px;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(69, 88, 115, 0.2);
    z-index: 1;
    max-width: 420px;
}

.title {
    color: #0f294d;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 12px;
}

.cityContainer {
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;

}

.cityTitle {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.07px;
    line-height: 20px;
}

.commonContent {
    font-size: 12px;
    font-weight: normal;
    line-height: 20px;
    color: #455873;
}

.greenColor {
    color: #24b381;
}

.yellowColor {
    color: #ff7700;
    ;
}


.footStyle {
    color: #8592a6;
    font-size: 12px;
    font-weight: normal;
    line-height: 20px;
}

.margin {
    margin: 4px 0;
}

.commonRestrictions {
    color: #ff7700 !important;
    font-size: 12px;
    font-weight: normal;
    line-height: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 7px;
    width: 100%;
    flex-wrap: wrap;
}
.cityBox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.marginRight {
    margin-right: 6px;
}
.imgStyle {
    width: 12px;
    height: 12px;
    margin: 0 4px; 
}
.wrapFont {
    white-space: pre-wrap;
}
.rightsInfo {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}