.loading {
	position: absolute;
	left: 0;
	bottom: 0;
	height: 4px;
	transform-origin: left center;
	bottom: 0;

	min-width: 20px;
	width: 100%;

	&:after,
	&:before {
		content: '';
		position: absolute;
		border-radius: unset;
	}
	&:before {
		background-color: #398efe;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		width: 100%;
		left: 0;
		top: -1px;
		right: 0;
		bottom: 0;
		height: 3px;
	}

	&.thin {
		height: 1px;
	}
	&.thin:before {
		top: 0;
		height: 1px;
	}

	&.fixed {
		top: 52px;
	}

	&.lower {
		z-index: 105;
		top: 68px;
		background: transparent;
	}

	&.disable-animation {
		-webkit-transition: width none !important;
		-o-transition: width none !important;
		transition: width none !important;
	}

	&.finish {
		opacity: 0;
		-webkit-transition: opacity 1s ease;
		-o-transition: opacity 1s ease;
		transition: opacity 1s ease;
	}

	&.process:before {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		margin-right: 10px;
	}

	&.fix-loading-hidden {
		position: fixed !important;
		visibility: hidden !important;
		top: -1000px !important;
	}

	&.hide-loading {
		animation: hide-loading 0.5s 1 forwards;
	}
	&.hide-loading-fix {
		animation: hide-loading-fix 0.5s 1 forwards;
	}
	&.fly-loading {
		animation: fly-loading 5s 1 forwards;
	}
	&.fly-loading-fix {
		animation: fly-loading-fix 5s 1 forwards;
	}
	&.reset-fly-loading {
		animation: reset-fly-loading 5s 1 forwards;
	}
	&.reset-fly-loading-fix {
		animation: reset-fly-loading-fix 5s 1 forwards;
	}

	:global {
		@keyframes hide-loading {
			0% {
				opacity: 1;
				-webkit-transform: scaleX(1);
				transform: scaleX(1);
			}
			99% {
				opacity: 0;
				-webkit-transform: scaleX(1);
				transform: scaleX(1);
			}
			to {
				opacity: 0;
				-webkit-transform: scaleX(0);
				transform: scaleX(0);
			}
		}

		@keyframes hide-loading-fix {
			0% {
				opacity: 1;
				-webkit-transform: scaleX(1);
				transform: scaleX(1);
			}
			99% {
				opacity: 0;
				-webkit-transform: scaleX(1);
				transform: scaleX(1);
			}
			to {
				opacity: 0;
				-webkit-transform: scaleX(0);
				transform: scaleX(0);
			}
		}

		@keyframes reset-fly-loading {
			0% {
				-webkit-transform: scaleX(0);
				transform: scaleX(0);
			}
			20% {
				-webkit-transform: scaleX(0.45);
				transform: scaleX(0.45);
			}
			45% {
				-webkit-transform: scaleX(0.7);
				transform: scaleX(0.7);
			}
			to {
				-webkit-transform: scaleX(1);
				transform: scaleX(1);
			}
		}

		@keyframes fly-loading {
			0% {
				-webkit-transform: scaleX(0);
				transform: scaleX(0);
			}
			20% {
				-webkit-transform: scaleX(0.45);
				transform: scaleX(0.45);
			}
			45% {
				-webkit-transform: scaleX(0.7);
				transform: scaleX(0.7);
			}
			to {
				-webkit-transform: scaleX(1);
				transform: scaleX(1);
			}
		}

		@keyframes fly-loading-fix {
			0% {
				-webkit-transform: scaleX(0);
				transform: scaleX(0);
			}
			20% {
				-webkit-transform: scaleX(0.45);
				transform: scaleX(0.45);
			}
			45% {
				-webkit-transform: scaleX(0.7);
				transform: scaleX(0.7);
			}
			to {
				-webkit-transform: scaleX(1);
				transform: scaleX(1);
			}
		}

		@keyframes reset-fly-loading-fix {
			0% {
				-webkit-transform: scaleX(0);
				transform: scaleX(0);
			}
			20% {
				-webkit-transform: scaleX(0.45);
				transform: scaleX(0.45);
			}
			45% {
				-webkit-transform: scaleX(0.7);
				transform: scaleX(0.7);
			}
			to {
				-webkit-transform: scaleX(1);
				transform: scaleX(1);
			}
		}
	}
}
