.basicPop {
    width: 624px !important;
}

.title {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0px;
    margin-bottom: 16px;
    :global(bound) {
        color: #333333;
    };
    :global(red) {
        color: #FF5500;
    }
}

.btn {
    width: 299px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    display: inline-block;
    border-radius: 4px;
    font-size: 14px;
    background: #ffffff;
    color: #3264ff;
    border: 1px solid #3264ff;
    margin-right: 24px;
}

.ghotstBtn {
    width: 299px;
    height: 36px;
    background: #3264ff;
    border-radius: 4px 4px 4px 4px;
    display: inline-block;
    line-height: 36px;
    text-align: center;
    font-size: 14px;
    background: #3264ff;
    color: #ffffff;
}

.liStyle {
    color: #555555;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.roundStyle {
    width: 4px;
    height: 4px;
    background: #888888;
    border-radius: 3.2px;
    margin-right: 4px;
}